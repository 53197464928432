import React from "react";
import { NavLink } from "react-router-dom";

const NewHero = () => {
  return (
    <div>
      <div className="container mx-auto px-6 py-12">
        <div className="flex flex-col items-center justify-center">
          <div className="p-1 flex items-center rounded-full bg-gray-800 gap-2 mb-5">
            <button className="text-white text-sm bg-purple-600 font-medium p-1 rounded-full">
              Start Now
            </button>
            <p className="dark:text-white text-white text-sm font-medium m-1">
              Showcase your work in the public now!!
            </p>
          </div>

          <h1 className="text-4xl text-center font-semibold dark:text-white mb-5">
            Welcome to <span className="text-purple-500">Prince Sahni </span>
            Portfolio Website
          </h1>
          <h2 className="text-6xl text-center font-bold  dark:text-white mb-5">
            Master Programming & Tech with Expert Content
          </h2>
          <p className="dark:text-gray-400 font-medium text-lg text-center mb-10">
            Explore our vast collection of YouTube tutorials, in-depth blog
            articles, and comprehensive programming courses. Whether you're just
            starting out or looking to sharpen your skills, we provide the tools
            and knowledge you need to succeed in the tech world.
          </p>
          <div className="p-1 flex items-center rounded-full border border-gray-300 gap-2 mb-5">
            <p className="dark:text-white font-medium m-2">
              Start Your journey
            </p>
            <button className="text-white bg-purple-500 font-medium p-2 rounded-full">
              <NavLink to="/courses">Try it now</NavLink>
            </button>
          </div>
        </div>
      </div>

      {/* Animated Logo  */}
      <div className="rounded-lg px-6 py-12 w-full sm:w-2/3 md:w-4/5 lg:w-1/2 mx-auto mb-10">
        <video
          autoPlay
          loop
          muted
          src="/Assets/www.princesahni.com.mp4"
          className="rounded-2xl w-full h-auto"
        ></video>
      </div>
    </div>
  );
};

export default NewHero;
