import React, { useState } from "react";

const Faqs = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAnswer = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What types of courses do you offer?",
      answer:
        "We offer a variety of programming courses covering topics from basic coding principles to advanced development techniques. Each course is designed to provide practical, hands-on learning experiences.",
    },
    {
      question: "How can I access the YouTube tutorials?",
      answer:
        "Our YouTube tutorials are available directly on their YouTube channel. You can access them anytime by visiting our channel through the link provided on our website.",
    },
    {
      question: "Are the blog articles free to read?",
      answer:
        "Yes, all of our blog articles are available for free. We aim to provide valuable insights and updates on technology and programming at no cost to our readers.",
    },
    {
      question: "Can I contact you for support or questions?",
      answer:
        "Absolutely! You can reach out to us through the contact form on our website. We’re here to help with any questions or support you may need.",
    },
    {
      question: "Do I need to create an account to access the full resources?",
      answer:
        "Yes, to access the full range of resources, including detailed courses and premium content, you need to create an account. This allows you to track your progress and enjoy personalized features.",
    },
  ];

  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="container px-6 py-12 mx-auto">
        <h2 className="uppercase text-center text-md font-semibold text-purple-600">
          FAQs
        </h2>
        <h3 className="text-2xl font-semibold text-center text-gray-800 lg:text-3xl dark:text-white">
          Frequently asked questions
        </h3>
        <p className="font-medium text-gray-600 dark:text-gray-400 m-auto text-center w-full sm:w-2/3 mt-2">
          Find answers to common questions about our resources, courses, and
          platform. If you have more questions, feel free to reach out to us.
        </p>

        <div className="mt-8 space-y-8 lg:mt-12">
          {faqData.map((item, index) => (
            <div
              key={index}
              className="p-8 bg-purple-50 rounded-lg dark:bg-gray-800"
            >
              <button
                className="flex items-center justify-between w-full"
                onClick={() => toggleAnswer(index)}
              >
                <h2 className="font-semibold text-gray-700 dark:text-white">
                  {item.question}
                </h2>

                <span
                  className={`${
                    openIndex === index
                      ? "text-white bg-purple-500"
                      : "text-gray-400 bg-gray-200"
                  } rounded-full`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    {openIndex === index ? (
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M18 12H6"
                      />
                    ) : (
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                      />
                    )}
                  </svg>
                </span>
              </button>

              {openIndex === index && (
                <p className="mt-6 text-sm text-gray-500 dark:text-gray-300">
                  {item.answer}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Faqs;
