import React from "react";
import { NavLink } from "react-router-dom";

const service2 = () => {
  return (
    <div className="mt-40 mb-40">
      <div className="w-4/5 sm:w-1/2 m-auto mb-10">
        <h2 className="font-bold text-center text-2xl dark:text-white">
          Unlock Your Full Potential with Our Curated Learning Resources
        </h2>
        <p className="font-light text-center text-gray-500 dark:text-gray-400">
          Discover a world of knowledge designed to help you master programming
          and technology. Benefit from expert-guided resources, top-rated
          YouTube lectures, comprehensive programming document courses, and
          insightful coding articles.
        </p>
      </div>

      <div className="flex flex-col sm:flex-row bg-purple-50 dark:bg-gray-800 border-2 border-purple-500 w-3/4 m-auto rounded-lg p-5 gap-4 items-center mb-20">
        {/* Left  */}
        <div className="flex flex-col gap-4 w-full sm:w-2/3">
          <div className="text-center flex items-center flex-col">
            <img
              className="h-32 object-cover"
              src="https://res.cloudinary.com/sniperdocs/image/upload/v1723527053/Prince_Corp.__1_-removebg-preview_wrjvgk.png"
              alt="Company Logo"
            />
            <h2 className="text-2xl font-bold text-purple-800 dark:text-purple-500">
              Why Choose Our Resources?
            </h2>
            <p className="text-black dark:text-white font-semibold text-lg">
              Our platform offers a variety of tools to support your learning
              journey, whether you're a beginner or an advanced programmer.
            </p>
          </div>

          <div className="grid grid-cols-2 bg-purple-200 border-2 border-purple-500 rounded-lg p-5 gap-4">
            <div className="flex gap-2">
              <svg
                class="w-6 h-6 text-purple-800"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fill-rule="evenodd"
                  d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                  clip-rule="evenodd"
                />
              </svg>
              <p className="font-semibold text-black">
                Guided Learning Pathways for Structured Growth
              </p>
            </div>
            <div className="flex gap-2">
              <svg
                class="w-6 h-6 text-purple-800"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fill-rule="evenodd"
                  d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                  clip-rule="evenodd"
                />
              </svg>

              <p className="font-semibold text-black">
                Access to the Best YouTube Lectures Curated by Experts
              </p>
            </div>
            <div className="flex gap-2">
              <svg
                class="w-6 h-6 text-purple-800"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fill-rule="evenodd"
                  d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                  clip-rule="evenodd"
                />
              </svg>

              <p className="font-semibold text-black">
                Comprehensive Programming Document Courses Tailored to Your
                Needs
              </p>
            </div>
            <div className="flex gap-2">
              <svg
                class="w-6 h-6 text-purple-800"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fill-rule="evenodd"
                  d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                  clip-rule="evenodd"
                />
              </svg>

              <p className="font-semibold text-black">
                In-Depth Coding Articles to Enhance Your Knowledge
              </p>
            </div>
          </div>

          <div className="flex flex-col md:flex-row items-center">
            <div className=" bg-purple-200 border-2 border-purple-500 rounded-lg p-5 text-center flex flex-col gap-2">
              <p className="font-medium text-black">
                Full Stack Specialisation
              </p>
              <p className="font-bold text-purple-800">
                7 Professional Projects to learn with real work-experience
              </p>
              <p className="font-light text-black">
                (MongoDB, Express, React, NodeJS)
              </p>
            </div>
            <h2 className="font-bold text-white text-lg bg-purple-800 p-2 rounded-full">
              <i>OR</i>
            </h2>
            <div className="bg-purple-200 border-2 border-purple-500 rounded-lg p-5 text-center flex flex-col gap-2">
              <p className="font-medium text-black">Backend Specialisation</p>
              <p className="font-bold text-purple-800">
                5 Professional projects to learn with real work-experience
              </p>
              <p className="font-light text-black">
                (Core Java and Spring Boot )
              </p>
            </div>
          </div>
        </div>

        {/* Right  */}
        <div className="w-full sm:w-1/3 flex flex-col gap-4">
          <div className="flex gap-2  bg-purple-200 border-2 border-purple-500 p-4 rounded-lg">
            <svg
              class="w-6 h-6 text-purple-200"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10 14v3m4-6V7a3 3 0 1 1 6 0v4M5 11h10a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1Z"
              />
            </svg>
            <div>
              <p className="font-medium text-black">Learning Tracks</p>
              <p className="font-bold text-purple-800">
                Guided Pathways for Every Skill Level
              </p>
            </div>
          </div>
          <div className="flex gap-2  bg-purple-200 border-2 border-purple-500 p-4 rounded-lg">
            <svg
              class="w-6 h-6 text-gray-200"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 10h16m-8-3V4M7 7V4m10 3V4M5 20h14a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Zm3-7h.01v.01H8V13Zm4 0h.01v.01H12V13Zm4 0h.01v.01H16V13Zm-8 4h.01v.01H8V17Zm4 0h.01v.01H12V17Zm4 0h.01v.01H16V17Z"
              />
            </svg>
            <div>
              <p className="font-medium text-black">Expert Lectures</p>
              <p className="font-bold text-purple-800">
                Top-Quality Youtube Content
              </p>
            </div>
          </div>
          <div className="flex gap-2  bg-purple-200 border-2 border-purple-500 p-4 rounded-lg">
            <svg
              class="w-6 h-6 text-gray-200"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 10h16m-8-3V4M7 7V4m10 3V4M5 20h14a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Zm3-7h.01v.01H8V13Zm4 0h.01v.01H12V13Zm4 0h.01v.01H16V13Zm-8 4h.01v.01H8V17Zm4 0h.01v.01H12V17Zm4 0h.01v.01H16V17Z"
              />
            </svg>

            <div>
              <p className="font-medium text-black">In-Depth Courses</p>
              <p className="font-bold text-purple-800">
                Comprehensive Programming Documents
              </p>
            </div>
          </div>
          <div className="flex gap-2  bg-purple-200 border-2 border-purple-500 p-4 rounded-lg">
            <svg
              class="w-6 h-6 text-gray-200"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8 7H5a2 2 0 0 0-2 2v4m5-6h8M8 7V5a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2m0 0h3a2 2 0 0 1 2 2v4m0 0v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-6m18 0s-4 2-9 2-9-2-9-2m9-2h.01"
              />
            </svg>

            <div>
              <p className="font-medium text-black">Insightful Articles</p>
              <p className="font-bold text-purple-800">
                Articles Covering the Latest in Tech
              </p>
            </div>
          </div>
          <button className="bg-purple-500 p-4 rounded-lg flex text-white font-semibold">
          <NavLink to='/youtube'>Youtube Videos </NavLink>
            
            <svg
              className="w-6 h-6 text-white font-semibold"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m9 5 7 7-7 7"
              />
            </svg>
          </button>
          <button className="bg-yellow-400 shadow-lg shadow-white p-4 rounded-lg flex text-black font-semibold">
            <NavLink to='/courses'>Explore Now </NavLink>
            <svg
              class="w-6 h-6 text-black font-semibold "
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m9 5 7 7-7 7"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default service2;
