import axios from "axios";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { baseUrl } from "../../App";

const Course = () => {
  // Make the State for the Course
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch all the Course
  const getAllCourses = async () => {
    try {
      const url = `${baseUrl}/api/v1/course/get`;
      await axios
        .get(url, {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        })
        .then((response) => {
          console.log("Response while getting all the Coureses ", response);
          setCourses(response.data.data);
        })
        .catch((error) => {
          console.log("Error while getting all the Courses", error);
        });
    } catch (error) {
      console.log("Error while getting the Courses ", error);
    } finally {
      setLoading(false);
    }
  };

  // Call the useEffect hook for automatica calling
  useEffect(() => {
    getAllCourses();
  }, []);

  // For Date Format
  const formatDate = (isoString) => {
    const date = new Date(isoString);

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = date.getUTCDate();
    const month = monthNames[date.getUTCMonth()]; // Get month name
    const year = date.getUTCFullYear();

    return `${day} ${month} ${year}`;
  };

  return (
    <div>
      <div className="container px-6 py-12 mx-auto mt-10 mb-10">
        <h1 className="text-center font-bold text-2xl mb-5 text-purple-500 dark:text-purple-400">
          Explore Our Programming Courses
        </h1>
        <p className="font-medium text-center w-2/3  m-auto mb-10 dark:text-gray-300">
          Dive into our range of structured programming courses designed to
          guide you through essential concepts and techniques. Each course
          offers practical insights and hands-on exercises to help you build and
          refine your coding skills.
        </p>

        {loading ? (
          <div
            role="status"
            className="flex gap-3 items-center justify-center mt-10"
          >
            <svg
              aria-hidden="true"
              class="inline w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-purple-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span class="text-center dark:text-white">Loading...</span>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {courses.map((item) => (
              <NavLink to={`/course/${item._id}`}>
                <div
                  key={item._id}
                  className="bg-purple-50 dark:bg-gray-800 rounded-lg  flex flex-col p-2"
                >
                  <img
                    className="h-48 object-cover rounded-lg"
                    src={item.courseImage}
                    alt="Course Banner Images"
                  />
                  <div className="mt-2 flex flex-col h-full justify-between">
                    <h3 className="font-semibold text-xl text-purple-500 dark:text-purple-400">
                      {item.title}
                    </h3>
                    <p className="font-normal hover:text-purple-500 mb-2 dark:text-gray-400">
                      {item.smallDesc.slice(0, 100)}...
                    </p>
                    <div className="flex items-center gap-2">
                      <img
                        src="https://res.cloudinary.com/sniperdocs/image/upload/v1723527053/Prince_Corp.__1_-removebg-preview_wrjvgk.png"
                        className="h-8 rounded-md"
                        alt="Flowbite Logo"
                      />
                      <p className="text-gray-500 font-semibold dark:text-gray-400">
                        {formatDate(item.createdAt)}
                      </p>
                    </div>
                  </div>
                </div>
              </NavLink>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Course;
