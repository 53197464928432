import React from "react";
import Testimonial from "./testimonial";
import Hero from "./hero";
import Service from "./service";
import Service2 from "./service2";
import Service3 from "./service3";
import Faqs from "./faqs";
import NewHero from "./newHero";
import Marquee from "./marquee";

const Home = () => {
  return (
    <div>
      <NewHero />
      <Hero />
      <Service />
      <Service3 />
      <Marquee />
      <Service2 />
      <Testimonial />
      <Faqs/>
    </div>
  );
};

export default Home;
