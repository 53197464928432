import React from "react";
import { NavLink } from "react-router-dom";

const Hero = () => {
  return (
    <div class="bg-gradient-to-b from-purple-300 via-purple-500 to-purple-700 pt-10 pb-10">
      <div className="w-4/5 flex flex-col md:flex-row items-center justify-between m-auto p-5 gap-5">
        <div className="w-full md:w-1/2 p-4 flex flex-col items-center text-center md:text-left md:items-start">
          <h2 className="font-bold text-4xl text-black">
            Explore Our In-Depth Programming Courses
          </h2>
          <p className="mt-5 mb-5 font-semibold text-gray-700">
            Advance your coding skills with our detailed and practical courses.
            Each course is meticulously designed to provide hands-on experience
            and real-world application, helping you to master essential
            programming concepts and techniques.
          </p>
          <button className="p-3 border font-medium text-white bg-black rounded-md  border-purple-500">
            <NavLink to="/courses">Explore Courses</NavLink>{" "}
            <i class="fa-solid fa-arrow-right"></i>
          </button>
          <div className="mt-5 mb-5 w-max p-2 flex gap-4 text-center border-2 border-gray-300 rounded-lg">
            <div>
              <h2 className="text-black font-bold text-xl">20+</h2>
              <p className="text-gray-700 font-medium">User Enrolled</p>
            </div>
            <div>
              <h2 className="text-black font-bold text-xl">40+</h2>
              <p className="text-gray-700 font-medium">Articles</p>
            </div>
            <div>
              <h2 className="text-black font-bold text-xl">10+</h2>
              <p className="text-gray-700 font-medium">Courses</p>
            </div>
          </div>
        </div>

        <div className="w-full md:w-1/2  p-4 ">
          <img
            src="/Assets/course_banner.png"
            alt=""
            className=" w-full h-full"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
