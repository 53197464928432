import React from "react";

const Service = () => {
  return (
    <div className="mt-40 mb-40">
      <div className="w-4/5 m-auto">
        <h2 className="text-purple-500 uppercase font-medium text-center">
          ONE-stop Learning Platform
        </h2>
        <p className="font-bold text-4xl text-center dark:text-white">
          Everything you need in your{" "}
          <span className="text-purple-500">Profile Dashboard</span>
        </p>

        <div className="flex flex-col md:flex-row items-center justify-between gap-4">
          <div className="p-4 w-full md:w-1/2">
            <p className="uppercase text-xl text-purple-500 font-bold">
              YOUr Profile Page
            </p>
            <h2 className="font-bold text-2xl mt-4 dark:text-white">
              Update and Manage Your Account Information Easily
            </h2>
            <p className="font-medium text-gray-500 dark:text-gray-400 mt-4">
              View and update your personal information, such as your name,
              email, and preferences. Ensure your details are up-to-date to make
              the most of your experience.
            </p>
          </div>

          <div className="p-4 w-full md:w-1/2">
            
            <img
              src="/Assets/Profile_Dashboard.png"
              alt=""
              className="w-full h-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
