import React from "react";

const service3 = () => {
  return (
    <div className="mt-40 mb-40">
      <div className="w-4/5 m-auto">
        <div className="mb-10 sm:w-2/3 w-full m-auto">
          <h2 className="font-bold text-2xl text-center mb-4 dark:text-white">
            Discover a Wealth of Learning Resources to Elevate Your Skills
          </h2>
          <p className="text-center text-gray-500 dark:text-gray-400">
            Discover a range of resources to support your learning journey.
            Whether you prefer video tutorials, insightful articles, or
            structured courses, we have something for every learner.
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
          <div className="bg-purple-100 dark:bg-gray-800 rounded-lg p-4">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/e/ef/Youtube_logo.png"
              alt=""
              className="w-20 rounded-lg mb-3"
            />

            <h2 className="text-black font-semibold text-lg dark:text-white">
              Interactive YouTube Tutorials
            </h2>
            <p className="text-gray-500 dark:text-gray-400">
              Watch and learn from our engaging video tutorials covering a
              variety of programming topics, designed to make complex concepts
              easier to understand.
            </p>
          </div>
          <div className="bg-purple-100 dark:bg-gray-800 rounded-lg p-4">
            <img
              src="https://img.freepik.com/premium-photo/blog-word-with-white-background_698953-5086.jpg?ga=GA1.1.1944613347.1723102474&semt=ais_hybrid"
              alt=""
              className="w-20 rounded-lg mb-3"
            />
            <h2 className="text-black font-semibold text-lg dark:text-white">
              In-Depth Coding Articles
            </h2>
            <p className="text-gray-500 dark:text-gray-400">
              Read our comprehensive blog articles to stay updated on the latest
              coding trends and techniques, with practical advice and expert
              insights.
            </p>
          </div>
          <div className="bg-purple-100 dark:bg-gray-800 rounded-lg p-4">
            <img
              src="https://img.freepik.com/premium-vector/courses-logo-icon-brand-identity-sign-symbol-template_880781-2160.jpg"
              alt=""
              className="w-20 rounded-lg mb-3"
            />
            <h2 className="text-black font-semibold text-lg dark:text-white">
              Structured Programming Courses
            </h2>
            <p className="text-gray-500 dark:text-gray-400">
              Explore our detailed courses to build your programming skills from
              the ground up, with step-by-step guidance and practical exercises.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default service3;
