import React from "react";
import Routes from "./routes/router";
import { AuthProvider } from "./AuthContext";

// export const baseUrl = "http://localhost:5000";
export const baseUrl = "https://portfolio-app-back.onrender.com";
// export const baseUrl = "https://portfolio-app-back.vercel.app";

function App() {
  return (
    <AuthProvider>
      <div className="dark:bg-gray-900">
        <Routes />
      </div>
    </AuthProvider>
  );
}

export default App;
