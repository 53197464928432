import React from "react";
import { NavLink } from "react-router-dom";

const Banner = () => {
  return (
    <div className="p-2 text-center bg-gradient-to-b from-blue-800 to-purple-800">
      <marquee behavior="" direction="">
        <div className="">
          <span className="text-white text-lg">
            {" "}
            <i>Checkout our latest work</i>: Try Out Our New Blog Website
          </span>
          <NavLink to="https://blog.princesahni.com/">
            <button className="bg-black rounded-md text-white font-bold p-1 m-2">
              Check now
            </button>
          </NavLink>
          <marquee behavior="" direction=""></marquee>
        </div>
      </marquee>
    </div>
  );
};

export default Banner;
