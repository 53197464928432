import React from "react";

const Marquee = () => {
  const row1 = [
    // "https://res.cloudinary.com/sniperdocs/image/upload/v1723527053/Prince_Corp.__1_-removebg-preview_wrjvgk.png",
    "https://res.cloudinary.com/sniperdocs/image/upload/v1724862828/studio_uls7ym.png",
    "https://res.cloudinary.com/sniperdocs/image/upload/v1724862820/browser_x_uusfow.png",
    "https://res.cloudinary.com/sniperdocs/image/upload/v1724862811/sg_df7h7j.png",
    "https://res.cloudinary.com/sniperdocs/image/upload/v1724862802/SRM_nihoxl.png",
    "https://res.cloudinary.com/sniperdocs/image/upload/v1724862793/Trans_sniojm.png",
    // "https://res.cloudinary.com/sniperdocs/image/upload/v1723527053/Prince_Corp.__1_-removebg-preview_wrjvgk.png",
    "https://res.cloudinary.com/sniperdocs/image/upload/v1724862828/studio_uls7ym.png",
    "https://res.cloudinary.com/sniperdocs/image/upload/v1724862820/browser_x_uusfow.png",
    "https://res.cloudinary.com/sniperdocs/image/upload/v1724862811/sg_df7h7j.png",
    "https://res.cloudinary.com/sniperdocs/image/upload/v1724862802/SRM_nihoxl.png",
    "https://res.cloudinary.com/sniperdocs/image/upload/v1724862793/Trans_sniojm.png",
  ];

  return (
    <div className="mt-40 mb-40">
      <div className="w-4/5 m-auto sm:w-2/3">
        <h2 className="uppercase text-center text-md font-semibold text-purple-600">
          Join a Thriving Community
        </h2>
        <p className="text-center text-2xl font-bold mt-2 dark:text-white">
          Who is growing with Us
        </p>
        <p className="text-center mt-4 dark:text-gray-400">
          Our platform is trusted by a diverse group of learners, from beginners
          taking their first steps in coding to professionals enhancing their
          skills. Join thousands who are advancing their careers and achieving
          their goals with our curated resources and expert guidance
        </p>
        <div className="flex overflow-hidden mt-10">
          <div className="flex space-x-16 animate-marquee">
            {row1.map((val, index) => (
              <div key={index} className="inline-block mx-8 self-center">
                <img
                  src={val}
                  alt={`Image ${index + 1}`}
                  className="h-16 rounded-lg max-w-none"
                />
              </div>
            ))}
          </div>

          <div className="flex space-x-16 animate-marquee" aria-hidden="true">
            {row1.map((val, index) => (
              <div key={index} className="inline-block mx-8 self-center">
                <img
                  src={val}
                  alt={`Image ${index + 1}`}
                  className="h-16 rounded-lg max-w-none"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Marquee;
