import axios from "axios";
import React, { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { baseUrl } from "../../App";

const FullCourse = () => {
  // Make the State to save the store the course Data
  const [fullCourse, setFullCourse] = useState({});
  const [bigDesc, setBigDesc] = useState([]);
  const [selectedContent, setSelectedContent] = useState(null);
  const [loading, setLoading] = useState(true);

  const [isSideBarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSideBarOpen);
  };

  // Get the Course Id
  const { courseId } = useParams();

  // Make function to call the data from API
  const getFullCourse = async () => {
    try {
      const url = `${baseUrl}/api/v1/course/get/${courseId}`;
      await axios
        .get(url, {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        })
        .then((response) => {
          console.log("Response getting of Full Course ", response);
          setFullCourse(response.data.data);
          setBigDesc(response.data.data.bigDesc);
          setSelectedContent(response.data.data.bigDesc[0]); // By Default Make the First One Selected.
        })
        .catch((error) => {
          console.log("Error while getting the Course Details ", error);
        });
    } catch (error) {
      console.log("Error while getting the Course Details ", error);
    } finally {
      setLoading(false);
    }
  };

  // use the useEffect Hook to automatically call this when the page is reload
  useEffect(() => {
    getFullCourse();
  }, [courseId]);

  const handleContentClick = (item) => {
    setSelectedContent(item);
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = date.getUTCDate();
    const month = monthNames[date.getUTCMonth()]; // Get month name
    const year = date.getUTCFullYear();

    return `${day} ${month} ${year}`;
  };

  return (
    <div>
      {loading ? (
        <div
          role="status"
          className="flex gap-3 items-center justify-center mt-10"
        >
          <svg
            aria-hidden="true"
            class="inline w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-purple-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span class="text-center dark:text-white">
            Loading Course Details ...
          </span>
        </div>
      ) : (
        <div>
          <button
            onClick={toggleSidebar}
            className="left-2 z-50 inline-flex items-center p-2 m-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          >
            <span className="sr-only">Open sidebar</span>
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                fillRule="evenodd"
                d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
              ></path>
            </svg>
          </button>

          <div className="flex">
            <div
              id="sidebar"
              className={`w-96  ${isSideBarOpen ? "block" : "hidden"} sm:block`}
            >
              <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
                <ul className="space-y-2 font-medium">
                  <li>
                    <p className="text-2xl text-purple-800 dark:text-purple-500 mb-5">
                      {fullCourse?.title}
                    </p>
                  </li>

                  {/* I want to show the Specific Content when the user click then render only that content in the body  */}
                  {bigDesc?.map((item) => (
                    <li>
                      <button
                        key={item._id}
                        onClick={() => handleContentClick(item)}
                        className="flex items-center p-2 text-gray-900 transition duration-75 rounded-lg hover:bg-purple-300 dark:hover:bg-gray-700 dark:text-white group"
                      >
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-purple-500 transition duration-75 dark:text-purple-500 group-hover:text-gray-900 dark:group-hover:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 16 20"
                        >
                          <path d="M16 14V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v15a3 3 0 0 0 3 3h12a1 1 0 0 0 0-2h-1v-2a2 2 0 0 0 2-2ZM4 2h2v12H4V2Zm8 16H3a1 1 0 0 1 0-2h9v2Z" />
                        </svg>
                        <span className="ms-3 text-start dark:text-gray-400">
                          {item.title}
                        </span>
                      </button>
                    </li>
                  ))}
                  <li className="flex gap-2 items-center p-2 text-gray-900 transition duration-75 rounded-lg hover:bg-purple-300">
                    <i className="font-bold text-purple-600 fa-solid text-xl fa-arrow-left-long"></i>
                    <NavLink
                      to="/courses"
                      className="text-gray-800 dark:text-gray-400"
                    >
                      Back To Courses
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>

            <div className="p-4">
              <div className="p-4 rounded-lg">
                {/* Here I want to render that data only not all the data here. */}
                {selectedContent ? (
                  <section
                    className="w-full sm:w-4/5"
                    key={selectedContent.title}
                    id={selectedContent.title}
                  >
                    <p className="text-purple-700 mt-2 dark:text-purple-500">
                      Published At : {formatDate(fullCourse?.createdAt)}
                    </p>
                    <img
                      className="h-96 object-cover w-full rounded-xl mt-2"
                      src={fullCourse?.courseImage}
                      alt=""
                    />
                    <h1 className="text-2xl font-bold mb-2 mt-5 dark:text-white">
                      {selectedContent.title}
                    </h1>
                    <p
                      className="text-gray-700 text-justify dark:text-gray-400"
                      dangerouslySetInnerHTML={{
                        __html: selectedContent.description,
                      }}
                    />
                  </section>
                ) : (
                  <p>{fullCourse?.smallDesc}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FullCourse;
